// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bK_kC";
export var caseStudyBackground__lineColor = "bK_kz";
export var caseStudyHead__imageWrapper = "bK_kv";
export var caseStudyProjectsSection = "bK_kD";
export var caseStudyQuote__bgQuote = "bK_lB";
export var caseStudyQuote__bgRing = "bK_kx";
export var caseStudyVideo__ring = "bK_kN";
export var caseStudy__bgDark = "bK_ks";
export var caseStudy__bgLight = "bK_kr";
export var caseStudy__bgLightGray = "bK_lz";